import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import michaelImg from "../images/team/michael2.jpg";
import vadimImg from "../images/team/vadim.jpg";
import maxImg from "../images/team/max2.jpg";

import { useStyles } from "./contentStyles";

export default function ContentKo(props) {
  const classes = useStyles();

  const [showEmail, setShowEmail] = useState(false);

  return (
    <div className={classes.root}>
      <div>
        <a id="services" className={classes.anchor} />
        <Typography variant="h4">서비스</Typography>
        <br />
        <ul>
          <li>
            프론트엔드 개발 <b>React</b> (Redux, Cypress, TypeScript)
          </li>
          <li>
            백엔드 개발 <b>Node.js</b> (Hapi, MongoDB, Postgres, Elastic Search)
          </li>
          <li>
            모바일 개발 (<b>Android, iOS</b>)
          </li>
          <li>
            <b>인프라 설정 및 애플리케이션 배포</b> (AWS, Yandex Cloud, Docker
            Swarm, Circle CI, Linode)
          </li>
          <li>
            <b>데이터 분석 및 기계 학습</b> (SQL, Python, NumPy, Scikit-learn)
          </li>
        </ul>
      </div>
      <div style={{ marginBottom: 20 }}>
        <a id="team" className={classes.anchor} />
        <Typography variant="h4">우리 팀</Typography>
        <br />
        <p>우리는 작지만 매우 효율적인 원격 근무자 팀입니다.</p>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={michaelImg}
                  title="Michael"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    Michael - JS/React/Node
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    저는 IT 분야에서 10년, React와 Node.js 분야에서 5년 동안
                    일했습니다. 저는 대기업에서 근무했으며 Upwork를 통해 미국과
                    유럽의 고객을 위해 원격으로 작업한 경험이 있습니다.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <a
                  href="https://github.com/klishevich"
                  rel="noreferrer noopener"
                  target="_blank"
                  className={classes.href}
                >
                  <Button size="small" color="primary">
                    Github
                  </Button>
                </a>
                <a
                  href="https://www.linkedin.com/in/klishevich"
                  rel="noreferrer noopener"
                  target="_blank"
                  className={classes.href}
                >
                  <Button size="small" color="primary">
                    LinkedIn
                  </Button>
                </a>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={vadimImg}
                  title="Vadim"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    Vadim - Android/Java
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    저는 IT 분야에서 15년의 경력을 갖고 있으며, 안드로이드
                    분야에서 5년 동안 일해왔습니다. 저는 Upwork를 통해 미국과
                    유럽의 고객을 위해 원격으로 작업한 경험이 있습니다.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={maxImg}
                  title="Max"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    Max - JS/React/Node
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    저는 5년 넘게 JavaScript 작업을 해왔습니다. 타입스크립트,
                    Node.js
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div>
        <a id="values" className={classes.anchor} />
        <Typography variant="h4">우리의 가치</Typography>
        <br />
        <ul>
          <li>장기적인 관계, 신뢰와 파트너십 구축</li>
          <li>우리가 즐기는 일을 하기 때문에 열심히 일하고 헌신하는 것</li>
          <li>빠르게 움직이고 열린 마음을 갖고</li>
        </ul>
      </div>
      <div>
        <a id="contacts" className={classes.anchor} />
        <Typography variant="h4">콘택트 렌즈</Typography>
        <br />
        <div>
          <div className={classes.contacts}>Busan IT Automation</div>
          <div className={classes.contacts}>주소: 47351, 부산 부산진구 신천대로65번길 9</div>
          <div className={classes.contacts}>
            Email:{" "}
            {!showEmail && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setShowEmail(true)}
              >
                보여주다
              </Button>
            )}
            {showEmail && (<span>busation @ gmail.com</span>)}
          </div>
        </div>
      </div>
      <div style={{ marginTop: 30 }}>
        <Typography variant="h4">영감을 주는 인용문</Typography>
        <br />
        <div>
          <div className={classes.dictum}>
            "명예로운 사람은 다른 사람들이 최선의 목표를 실현하도록 돕지만
            그렇지 않습니다. 그들의 최악의 욕망. 소심한 사람은 그 반대입니다." -{" "}
            <i>공자</i>
          </div>
          <div className={classes.dictum}>
            "죽을 때가 되면 죽어야 하는 사람은 나니까, 그러니 나는 내가 원하는
            대로 내 삶을 살아요." - <i>지미 헨드릭스</i>
          </div>
          <div className={classes.dictum}>
            "좋은 친구, 좋은 책, 그리고 졸린 양심, 이것이 바로 이상적인 삶." -{" "}
            <i>마크 트웨인</i>
          </div>
          <div className={classes.dictum}>
            "인생은 자전거를 타는 것과 같습니다. 균형을 유지하려면 다음을
            유지해야 합니다. 움직이는." - <i>알베르트 아인슈타인</i>
          </div>
        </div>
      </div>
    </div>
  );
}

ContentKo.propTypes = {
  title: PropTypes.string
};

ContentKo.defaultProps = {
  title: ""
};
