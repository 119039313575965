import React from "react";
import { menuItemsKo } from "../constants/menuItems";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";
import ContentKo from "../components/contentKo";
import Footer from "../components/footer";
import { SITE_TITLE_KO } from "../constants/siteMetadata";
import ClientOnly from "../components/client-only";

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="한국 부산에서의 React 및 Node.js 개발"
        description="부산오토메이션(Busan IT Automation)은 한국에서 React, Node.js, 모바일, iOS, Android 개발, DevOps 및 데이터 분석을 제공합니다."
      />
      <ClientOnly>
        <Header title={SITE_TITLE_KO} drawerMenuItems={menuItemsKo} />
      </ClientOnly>
      <ClientOnly>
        <ContentKo title={SITE_TITLE_KO} />
      </ClientOnly>
      <ClientOnly>
        <Footer />
      </ClientOnly>
    </Layout>
  );
};

export default IndexPage;
